.App {
    
    .wrapper {

        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

    }

    h2 {
        font-family:'ProximaNovaSemiBold', Courier, monospace;
        font-size: 18px;
        color: $azul;
    }
}