.container {
    h4 {
        margin-bottom: 5px;
    }

    form {
        display: flex;
        flex-wrap: wrap;
        background-color: lighten($color: $cinza-1, $amount: 30);
        margin: 20px 0;
        .input {
            margin: 20px 0 20px 20px;
            
            display: inline-block;

        }
        .dateRange {
          height: 39px;
          border: 1px solid $cinza-1 !important;
          border-radius: 3px;
          color: $cinza-2 !important;
        }

        button {
          margin: 20px 0 20px 20px;
        }

        .clean {
          background-color: lighten($color: $azul, $amount: 30);
        }
        
        width: 100%;
    }

    table {
        width: 100%;

        thead {
            background-color: $azul;
            color: white;
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: lighten($color: $cinza-1, $amount: 30);
                }

                &:hover {
                    background-color: lighten($color: $azul, $amount: 30);
                    color: white;

                    button {
                        color: white;

                    }
                }

                td {
                    padding: 0 7px;
                    button {
                        background-color: transparent;
                        color: $azul;
                        border: none;
                        padding: 10px;
                        cursor: pointer;
                        width: 100%;
                        font-family: 'ProximaNovaSemiBold', sans-serif;
                        font-size: 14px;
                    }

                }
            }
        }

        tr {
            height: 30px;
        }
    }
}

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;  
   background-color: $azul;
   margin: 10px 2px 0 2px;
   height: 40px;
   border-radius: 10px 10px 0 0;

  h2 {
    margin: 0;
    margin-left: 10px;
    color: white;
  }

  .icons {
    display: flex;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 25px;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      color: $azul;
      cursor: pointer;
    }

    .disabled {
      color: $cinza-1 !important;
      cursor: context-menu !important
    }
  }
}

.no-data {
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
  background-color: lighten($color: $cinza-1, $amount: 40);
}

.totalizers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.totalizers-box {
  display: flex;
  border: solid 1px lighten($color: $cinza-1, $amount: 30);
  border-radius: 10px;
  width: min-content;
  margin-left: auto;

  div {
    height: 70px;
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5px;
    min-width: 100px;
    width: max-content;
    &:nth-child(even) {
      background-color: lighten($color: $cinza-1, $amount: 30);
    }

    p {
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    span {
      align-self: flex-end;
    }
    
  }

}